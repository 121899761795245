<template>
  <ProductRecoSingleFocus>
    <template #media>
      <MediaInterpreter :media="updatedMedia" />
    </template>
    <template v-if="products?.elements?.length > 0" #product-card>
      <!-- TODO: Add later product image on hover -->
      <ProductCard
        class="md:hidden"
        :product="products?.elements?.[0]"
        size="double"
      />
      <ProductCard
        class="hidden md:block"
        size="large"
        :product="products?.elements?.[0]"
      />
    </template>
  </ProductRecoSingleFocus>
</template>

<script setup lang="ts">
import MediaInterpreter from "@/components/atoms/MediaInterpreter";
import ProductRecoSingleFocus from "@/components/organisms/ProductRecoSingleFocus";
import ProductCard from "@/components/product/ProductCard.vue";
import { useCmsBlock } from "@shopware-pwa/composables-next";
import type { CmsBlock, Media } from "@shopware-pwa/types";
import type { ExtendedCmsSlot } from "@/types/cms";
import { mediaUpdater } from "@/utils/mediaUpdater";
const props = defineProps<{
  content: CmsBlock;
}>();
const { getSlotContent } = useCmsBlock(props.content);

const leftTopContent = getSlotContent("leftTop") as ExtendedCmsSlot;
const rightTopContent = getSlotContent("rightTop") as ExtendedCmsSlot;

const { apiClient } = useShopwareContext();

const updatedMedia = await mediaUpdater(leftTopContent.config?.media.value);
const products = await apiClient.invoke("readProduct post /product", {
  ids: rightTopContent.config?.products?.value,
  associations: {
    media: {},
    seoUrls: {},
  },
});
</script>

import type { Media } from "@shopware-pwa/types";
import { getValueByPath, setValueByPath } from "@/utils/objectPathMethods";
import { useStoreApi } from "@/composables/useStoreApi";

export const mediaUpdater = async (
  arrOrMediaCandidate: any[] | Media,
  entityPathsToMedia?: string[],
) => {
  const { getMediaByMediaIds } = useStoreApi();

  if (!Array.isArray(arrOrMediaCandidate) && arrOrMediaCandidate?.id) {
    const updatedMedia = await getMediaByMediaIds([arrOrMediaCandidate.id]);
    return updatedMedia?.[0];
  }

  if (!Array.isArray(arrOrMediaCandidate)) return arrOrMediaCandidate;

  const arr = arrOrMediaCandidate as any[];

  if (!entityPathsToMedia || entityPathsToMedia.length === 0) {
    return arr;
  }
  const mediaIds = arr.reduce((acc, entity) => {
    for (let entityPathToMedia of entityPathsToMedia) {
      const entityDetails = getValueByPath(entity, entityPathToMedia) as Media;
      if (entityDetails && entityDetails.id) {
        acc.push(entityDetails.id);
      }
    }

    return acc;
  }, [] as string[]);
  if (mediaIds.length === 0) return arr;

  const { data: medias } = await getMediaByMediaIds(mediaIds);

  if (medias?.value && medias?.value?.length > 0) {
    return arr.map((entity) => {
      for (let entityPathToMedia of entityPathsToMedia) {
        const entityDetails = getValueByPath(
          entity,
          entityPathToMedia,
        ) as Media;

        if (
          entityDetails &&
          entityDetails.id &&
          mediaIds.includes(entityDetails.id)
        ) {
          const mediaCandidate = (medias.value as Media[]).find(
            (media) => media.id === entityDetails.id,
          );

          if (mediaCandidate) {
            setValueByPath(entity, entityPathToMedia, mediaCandidate);
          }
        }
      }

      return entity;
    });
  }

  return arr;
};
